import React, {
    FC,
    useEffect,
} from 'react';

import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import MFAModal from '../../../../Common/MFAModal';
import { useStores } from '../../../../hooks';
import { OpenOptions } from '../../../../../config/openOptions';
import { batchActionsNamespace } from '../nameSpace';
import styles from './BatchDownloadButton.module.scss';

const MESSAGE_DURATION_SECONDS = 8;

const BatchDownloadButton: FC = observer(() => {
    const { t } = useTranslation();

    const {
        filesListStore,
        authSettingsStore,
        filesAccessStore,
    } = useStores();

    const {
        isMFAMounted,
        setIsMFAMounted,
        setIsMFAVisible
    } = filesAccessStore;

    const {
        batchDownloadError,
        isBatchDownloadLoading,
    } = filesAccessStore;

    const unmountMFA = (): void => {
        setIsMFAMounted(false);
    };

    const mountMFA = (): void => {
        setIsMFAMounted(true);
        setIsMFAVisible(true);
    };

    useEffect(() => {
        if (batchDownloadError) {
            if (batchDownloadError === 'MFARequired' && !authSettingsStore.skipMFA) {
                mountMFA();
            } else if (batchDownloadError === 'MFAFailed') {
                setIsMFAVisible(true);
            } else if (batchDownloadError === 'MFALimitReached') {
                unmountMFA();
                message.error(t(`${batchActionsNamespace}.checkCodeLimit`));
            } else {
                message.error(t(`${batchActionsNamespace}.couldNotStartDownload`));
            }
        }
    }, [batchDownloadError]);

    const onClickBatchLoading = async (mfaCode?: string): Promise<void> => {
        const filesListType = filesListStore.displayFilesType;

        const downloadableFilesIds = filesListStore.selectedItems.reduce<string[]>((acc, {
            menu,
            fid,
            is_folder: isFolder,
            is_workspace: isWorkspace,
        }) => {
            if (
                isFolder
                || isWorkspace
                || menu?.options?.includes(OpenOptions.download)
            ) {
                acc.push(fid);
            }
            return acc;
        }, []);

        if (!downloadableFilesIds.length) {
            message.info(t(`${batchActionsNamespace}.noFiles`), MESSAGE_DURATION_SECONDS);
        } else {
            const isSucceed = await filesAccessStore.tryStartBatchDownload(downloadableFilesIds, mfaCode);
            if (isSucceed) {
                filesListStore.setSelectedItems(filesListType, []);
            }
        }
    };

    return (
        <>
            <Button
                className={styles['download-button']}
                icon={<DownloadOutlined className={styles.icon} />}
                loading={isBatchDownloadLoading}
                onClick={() => onClickBatchLoading()}
            >
                {t(`${batchActionsNamespace}.download`)}
            </Button>
            {isMFAMounted && <MFAModal />}
        </>
    );
});

export default BatchDownloadButton;
