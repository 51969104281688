import { defineAbility } from '@casl/ability';

import { captureErrorForSentry } from '@/components/utils';

const ALL = 'ALL';

export enum SpecterXRules {
    SpecterxShareFiles = 'SpecterxShareFiles',
    SpecterxAdminRights = 'SpecterxAdminRights',
    SpecterxEditFiles = 'SpecterxEditFiles',
    SpecterxEditPolicy = 'SpecterxEditPolicy',
    SpecterxListPolicy = 'SpecterxListPolicy',
    SpecterxEditUsers = 'SpecterxEditUsers',
    SpecterxChangeFilePolicy = 'SpecterxChangeFilePolicy',
    SpecterxListPolicySettings = 'SpectersListPolicySettings',
    SpecterxOwnFiles = 'SpecterxOwnFiles',
    SpecterxListFiles = 'SpecterxListFiles',
    SpecterxUnShareFiles = 'SpectersUnShareFiles',
    SpecterxDownloadFiles = 'SpecterxDownloadFiles',
    SpecterxAuditLogs = 'SpecterxAuditLogs',
    SpecterxCreateFiles = 'SpecterxCreateFiles',
    SpecterXListUsers = 'SpecterXListUsers',
}

// TODO: remove hardcoded rules
export const WSO_RULES = Object.values(SpecterXRules).map((action) => ({
    action, subject: ALL,
}));

const ability = defineAbility((can, cannot) => {
    can(['read'], ALL);
});

// TODO: add type annotation for idToken
export const tryUpdateRulesFromToken = (idToken): void => {
    try {
        const rules = JSON.parse(idToken.payload.rules);
        const actions =  [...new Set<string>(rules[0].actions)];
        ability.update(actions.map((action) => ({ action, subject: ALL })));
    } catch (error) {
        captureErrorForSentry(error, 'ability.tryUpdateRulesFromToken');
    }
};

export default ability;
