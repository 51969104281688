import { FC, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import PhoneInput, { getCountryCallingCode, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';

import { PhoneIdentity } from '@/types/types';
import { phoneNameSpace } from '../PhoneComp';
import styles from './PhoneInputEdit.module.scss';

interface PhoneInputEditProps extends PhoneIdentity {
    onClose?: (initValue: PhoneIdentity) => void;
    onSubmit?: (value?: PhoneIdentity) => void;
}

const PhoneInputEdit: FC<PhoneInputEditProps> = ({
    onClose,
    onSubmit,
    phone,
    prefix,
}) => {
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const initialValuesRef = useRef<PhoneIdentity | null>({ prefix, phone });

    const { t } = useTranslation();

    const checkIsPhoneValid = (): boolean => isValidPhoneNumber(phoneNumberValue);

    const submitPhone = (): void => {
        if (checkIsPhoneValid()) {
            const phoneNumber = parsePhoneNumber(phoneNumberValue);
            const phoneNumberCallingCode = getCountryCallingCode(phoneNumber.country);
            onSubmit({ phone: phoneNumber.nationalNumber, prefix: phoneNumberCallingCode });
        } else {
            message.error(t(`${phoneNameSpace}.invalidPhone`));
        }
    };

    const handleSubmit = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        submitPhone();
    };

    const handleKeyUp = (e: React.KeyboardEvent): void => {
        if (e.key === 'Enter') {
            submitPhone();
        }
    };

    const cancelEditing = (e: React.SyntheticEvent): void => {
        e.preventDefault();
        onClose?.(initialValuesRef.current);
    };

    return (
        <div className={styles['phone-edit-container']}>
            <PhoneInput
                className={styles['phone-input']}
                value={phoneNumberValue}
                onChange={setPhoneNumberValue}
                onKeyUp={handleKeyUp}
                defaultCountry="IL"
                international
                smartCaret
                flags={flags}
                limitMaxLength
            />
            <div className={styles['action-buttons-container']}>
                <Button
                    type="link"
                    className={styles['submit-button']}
                    onClick={handleSubmit}
                >
                    <CheckOutlined className={styles.icon} />
                </Button>
                <Button
                    type="link"
                    onClick={cancelEditing}
                    className={styles['close-button']}
                >
                    <CloseOutlined className={styles.icon} />
                </Button>
            </div>
        </div>
    );
};

export default PhoneInputEdit;
