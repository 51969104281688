import { LANGUAGES } from '@/content/languages';
import { APP_ROUTES_DEFAULT, AppRoutes, RoutesListType } from './appRoutes';

interface WSO {
    CLIENT_SECRET: string;
    CLIENT_ID: string;
    DOMAIN: string;
    REDIRECT_URL: string;
}

export interface API_PROXY {
    CHECK_APP_INFO_BEFORE_USING: boolean;
    API_URL: string;
    APP_URL: string;
    DISABLE_MFA_BEHIND_PROXY: boolean;
    DISABLE_OTP_BEHIND_PROXY: boolean;
}

export enum SSOTypes {
    cognito = 'cognito',
    google = 'google',
    microsoft = 'microsoft',
    okta = 'okta',
}

interface Cognito {
    USER_POOL_ID: string;
    APP_CLIENT_ID: string;
    IDENTITY_POOL_ID: string;
    DOMAIN: string;
}

export interface ViewerConfig {
    CANCEL_MOBILE_VIEWER_FILE_EXTENSION_CHECK?: boolean;
    HIDE_DOWNLOAD_BUTTON?: boolean;
}

export interface EnvConfig {
    COMPANY_LOGO: string;
    HEADER_COMPANY_LOGO: string;
    HEADER_SMALL_COMPANY_LOGO: string;
    API_URL: string;
    APP_URL: string;
    cognito: Cognito;
    WSO?: WSO;
    API_PROXY?: API_PROXY;
    SSO: SSOTypes[];
    DEFAULT_LANGUAGE?: LANGUAGES;
    HAS_FULL_OTP_AUTH?: boolean;
    SHOW_FEDERATED_POPUP?: boolean;
    DISABLE_GOOGLE_REGISTER_CHECK?: boolean;
    HIDE_SHARED_FILE_POLICIES?: boolean;
    HIDE_UPLOAD_BUTTON?: boolean;
    DISALLOW_SHARE_WHILE_FILE_UPLOADING?: boolean;
    DISABLE_CREATE_FOLDER?: boolean;
    viewer?: ViewerConfig;
    HIDE_FAQ_BUTTON?: boolean;
    ROUTES?: RoutesListType;
    ENABLE_WORKSPACE?: boolean;
    ENABLE_IP_RESTRICTION?: boolean;
    SHOW_UPLOAD_DISCLAIMER?: boolean;
    DISABLE_SELF_PHONE_FILLING?: boolean;
    ENABLE_OPSWAT_FILE_SCANNING?: boolean;
    UPLOAD_FILES_COUNT_LIMIT?: number;
    SHOW_SHARE_BACK_BUTTON?: boolean;
    ENABLE_SHARE_MESSAGE?: boolean;
    DEFAULT_PHONE_CHECK?: boolean;
    IS_MULTITENANCY?: boolean;
}

const dev: EnvConfig = {
    COMPANY_LOGO: '/assets/company_logo/Specter-X-Logo-Light-M.png',
    HEADER_COMPANY_LOGO: '/assets/header_company_logo/Specter-X-Logo-Dark.svg',
    HEADER_SMALL_COMPANY_LOGO: '/assets/header_company_logo/small_logos/specterx-icon.png',
    API_URL: 'https://dev-api.specterx.com',
    APP_URL: 'https://dev-app.specterx.com',
    cognito: {
        USER_POOL_ID: 'eu-central-1_4f3TZWBv1',
        APP_CLIENT_ID: '65fa7l3nh48aecks3c0vq0r6k5',
        IDENTITY_POOL_ID: 'eu-central-1:fba0a33d-513f-4983-81d7-27e570fa33d0',
        DOMAIN: 'dev-users.auth.eu-central-1.amazoncognito.com',
    },
    SSO: [SSOTypes.cognito, SSOTypes.microsoft, SSOTypes.google, SSOTypes.okta],
    DISABLE_GOOGLE_REGISTER_CHECK: false,
    ENABLE_OPSWAT_FILE_SCANNING: true,
    SHOW_SHARE_BACK_BUTTON: true,
};

let config: EnvConfig;
config = dev;
const appConfig: Readonly<EnvConfig> = {
    // Add common config values here

    DEFAULT_LANGUAGE: LANGUAGES.en,
    HAS_FULL_OTP_AUTH: true,
    SHOW_FEDERATED_POPUP: false,
    DISABLE_GOOGLE_REGISTER_CHECK: true,
    DISABLE_CREATE_FOLDER: false,
    HIDE_SHARED_FILE_POLICIES: false,
    DISALLOW_SHARE_WHILE_FILE_UPLOADING: false,
    HIDE_FAQ_BUTTON: true,
    HIDE_UPLOAD_BUTTON: false,
    ENABLE_WORKSPACE: true,
    ROUTES: APP_ROUTES_DEFAULT,
    DISABLE_SELF_PHONE_FILLING: true,
    SHOW_SHARE_BACK_BUTTON: true,
    IS_MULTITENANCY: false,
    ENABLE_SHARE_MESSAGE: true,
    ...config,
};

export const IS_WSO = 'WSO' in config;
export const HAS_API_PROXY = 'API_PROXY' in config;

export const IS_LOCAL_ENV = import.meta.env.DEV;
export const IS_NON_PROD_ENV = IS_LOCAL_ENV || ['staging', 'dev'].includes(import.meta.env.VITE_STAGE);
export const IS_UNSTABLE_ENV = IS_LOCAL_ENV || import.meta.env.VITE_STAGE === 'dev';

export const IS_ONLY_SSO = appConfig.SSO.length === 1;
export const ALL_SSO: SSOTypes[] = Object.values(SSOTypes);
export const SSO_ORDER: Partial<Record<SSOTypes, number>> = {
    [SSOTypes.microsoft]: 1,
    [SSOTypes.okta]: 2,
    [SSOTypes.google]: 0,
};

Object.freeze(appConfig);

export default appConfig;
