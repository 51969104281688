import { ROOT_FOLDER } from '@/consts';
import { Endpoint, ENDPOINTS } from '../../../api';
import { FileStatus, SecuredFile, UserIdentity } from '../../../types/types';
import {
    FilesListType,
    FilesQueryParams,
    LoadParams,
    Ordering,
} from '../interfaces';
import { Dict, dictToQuery, eqUserIdentities } from '../../../components/utils';
import { parseFolderId } from './folders';

const EXCLUDE_STATUSES: FileStatus[] = ['error', 'exporting', 'uploading', 'scanning', 'cleaning', 'scan_blocked', 'deleted'];
const EXCLUDE_STATUSES_STR: string = EXCLUDE_STATUSES.join(',');

/* eslint-disable camelcase */
interface QueryDict extends Omit<FilesQueryParams, 'filesListType' | 'ordering'> {
    readonly order_by?: string;
}

const orderDirectionToQuery = (isDescending: boolean): string => (
    isDescending ? '-' : ''
);

const orderingToQuery = (ordering: Ordering): string => (
    ordering
        ? `${orderDirectionToQuery(ordering.isDescending)}${ordering.orderBy}`
        : ''
);

export const getFilesQuerystring = ({
    filesListType,
    email,
    parent_folder,
    search_string,
    ordering,
    ...rest
}: FilesQueryParams): string => {
    /*
    * Because search is recursive, it's incompatible with folder navigation.
    * In current UX folder should have more priority than search
    * */
    const parenFolderQueryParam = parent_folder === ROOT_FOLDER ? '' : parent_folder;
    const queryDict: QueryDict = {
        ...rest,
        parent_folder: parenFolderQueryParam,
        search_string: parenFolderQueryParam ? '' : search_string,
        email: filesListType === 'allFiles' && !parenFolderQueryParam ? email : null,
        order_by: orderingToQuery(ordering),
    };
    let query = `?${dictToQuery(queryDict as unknown as Dict)}`;
    if (filesListType === 'sharedFiles') {
        query = `${query}&sharedWithMe=True`;
    } else if (filesListType === 'allFiles') {
        query = `/all${query}`;
    }
    return `${query}&exclude_statuses=${EXCLUDE_STATUSES_STR}`;
};
/* eslint-enable camelcase */

export const loadParamsToQueryParams = ({ folderKey, ...rest }: LoadParams): FilesQueryParams => ({
    ...rest,
    parent_folder: parseFolderId(folderKey),
});

interface DeleteFilePayload {
    readonly file: SecuredFile;
    readonly filesListType: FilesListType;
    readonly currentUserIdentity: UserIdentity;
}

export const getDeleteFileEndpoint = ({
    file,
    filesListType,
    currentUserIdentity,
}: DeleteFilePayload): Endpoint => {
    const isOwner = eqUserIdentities(currentUserIdentity, { id: file.owner_id, email: file.owner_email });
    const { file_id: fileId } = file;
    return ((filesListType !== 'sharedFiles' || isOwner)
        ? ENDPOINTS.deleteFile(fileId)
        : ENDPOINTS.shareFile(fileId, currentUserIdentity.id)
    );
};
