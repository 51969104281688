import {
    action,
    computed,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
} from 'mobx';

import { BASEURL, CancellableAPI, ENDPOINTS } from '@/api';
import i18n from '@/content';
import {
    captureErrorForSentry, checkIs404, checkIsPermissionsFailedError, downloadFile,
} from '@/components/utils';

import AuthSettingsStore from '../AuthSettingsStore';
import { BatchDownload, AsyncDownloadItem, StatusCheckWorker } from './interfaces';
import { getBatchDownloadStatus, getFileDownloadStatus } from './helpers';
import { DownloadActionResult } from '@/stores/FilesAccessStore/interfaces';
import { openFile } from '@/stores/FilesAccessStore';
import { OpenOptions } from '@/config/openOptions';

const CREATE_BATCH_KEY = '__CREATE_BATCH_KEY__';
const POLLING_INTERVAL_MS = 10_000;
const batchActionsNameSpace = 'filesTable.extraContent.batchActions';

class BatchDownloadStore {
    constructor(authSettingsStore: AuthSettingsStore) {
        this.API = new CancellableAPI(authSettingsStore, { failSilently: false, isPeriodic: true });
        makeObservable(this);
        this.watchOnDownloads();
        this.authSettingsStore = authSettingsStore;
    }

    private readonly API: CancellableAPI;

    private readonly processManagementMap: Map<string, NodeJS.Timeout> = new Map<string, NodeJS.Timeout>();

    private downloadsMapReaction: IReactionDisposer;

    private readonly authSettingsStore: AuthSettingsStore;

    @observable downloadsMap: Map<string, AsyncDownloadItem> = new Map<string, AsyncDownloadItem>();

    @observable isWidgetOpen = false;

    @observable MFARequiredFilesList: string[] = [];

    @computed
    get downloadsList(): AsyncDownloadItem[] {
        return [...this.downloadsMap.values()];
    }

    @computed
    get downloadedItemsCount(): number {
        return [...this.downloadsMap.values()].filter((item) => item.status === 'DONE').length;
    }

    @action
    setMFARequiredFilesList = (value: string[]): void => {
        this.MFARequiredFilesList = value;
    };

    @action
    setDownloadsMap = (downloadsMap: Map<string, AsyncDownloadItem>): void => {
        this.downloadsMap = downloadsMap;
    };

    @action
    setBatchDownload = (batchId: string, batchDownload: AsyncDownloadItem): void => {
        this.downloadsMap.set(batchId, batchDownload);
    };

    @action
    setIsProgressWidgetOpen = (value: boolean): void => {
        this.isWidgetOpen = value;
    };

    @action
    clear = (): void => {
        this.downloadsMap.clear();
        this.processManagementMap.forEach((timeout) => clearTimeout(timeout));
        this.API.cancelAll();
        this.processManagementMap.clear();
    };

    cancel = (batchId: string): void => {
        this.API.cancelRequest(batchId);
        clearTimeout(this.processManagementMap.get(batchId));
        this.remove(batchId);
    };

    @action
    remove = (batchId: string): void => {
        this.processManagementMap.delete(batchId);
        this.downloadsMap.delete(batchId);
    };

    startBatchDownload = async (filesIds: string[], mfaCode?: string): Promise<void> => {
        const { API } = this;
        const { result } = await API.post<BatchDownload>(
            BASEURL.backend(),
            ENDPOINTS.startBatchDownload(),
            CREATE_BATCH_KEY, // Actually this request doesn't suppose to be cancellable
            { body: { files: filesIds, auth_code: mfaCode } },
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {
            batch_id: id, user_id: userId, files, ...rest
        } = result;
        this.setBatchDownload(result.batch_id, { id, ...rest });
        this.tryScheduleStatusCheck(result.batch_id, getBatchDownloadStatus);
    };

    private tryScheduleStatusCheck(
        itemId: string,
        processStatusCheck: StatusCheckWorker,
        failOn500Status = false,
    ): void {
        if (this.downloadsMap.has(itemId)) {
            const scheduleTimeout = setTimeout(
                () => this.tryUpdateStatus(itemId, processStatusCheck, failOn500Status),
                POLLING_INTERVAL_MS,
            );
            this.processManagementMap.set(itemId, scheduleTimeout);
        }
    }

    private processStatusCheckResult(itemId: string, result: AsyncDownloadItem): boolean {
        let shouldReschedule = true;
        if (this.downloadsMap.has(itemId)) {
            const { status, link } = result;
            this.setBatchDownload(itemId, result);
            shouldReschedule = status === 'PENDING' || status === 'PROCESSING';
            if (status === 'DONE' && link) {
                downloadFile(link);
            }
            shouldReschedule = status !== 'DONE' && status !== 'ERROR' && status !== 'DELETED';
        } else {
            shouldReschedule = false;
        }
        return shouldReschedule;
    }

    private processSuccessfulResult(itemId: string, result: DownloadActionResult): void {
        const { status, link } = result;
        if (this.downloadsMap.has(itemId) && status === 'success') {
            downloadFile(link);
            this.partialUpdateBatchDownload(itemId, {status: 'DONE'});
        }
    }

    private processStatusCheckError(itemId: string, error: unknown, failOn500Status: boolean, isProtected: boolean): boolean {
        let shouldReschedule = true;
        const { API } = this;
        if (API.checkIsCancel(error)) {
            shouldReschedule = false;
        } else {
            captureErrorForSentry(error, 'BatchDownloadStore.checkStatus');
            if (isProtected) {
                shouldReschedule = false;
                this.partialUpdateBatchDownload(itemId,
                    {
                        message: i18n.t(`${batchActionsNameSpace}.protectedDownloadFails`),
                        status: 'ERROR',
                    });
            } else if (failOn500Status) {
                shouldReschedule = false;
                this.partialUpdateBatchDownload(itemId,
                    {
                        message: i18n.t(`${batchActionsNameSpace}.downloadFails`),
                        status: 'ERROR',
                    });
            } else if (checkIs404(error)) {
                this.partialUpdateBatchDownload(itemId,
                    {
                        message: 'Download process was removed',
                        status: 'ERROR',
                    });
                shouldReschedule = false;
            } else if (checkIsPermissionsFailedError(error)) {
                this.partialUpdateBatchDownload(itemId,
                    {
                        message: i18n.t(`${batchActionsNameSpace}.downloadPermissionFailed`),
                        status: 'ERROR',
                    });
                shouldReschedule = false;
            }
        }
        return shouldReschedule;
    }

    private async tryUpdateStatus(
        itemId: string,
        processStatusCheck: StatusCheckWorker,
        failOn500Status = false,
        isProtected = false,
    ): Promise<void> {
        if (this.downloadsMap.has(itemId)) {
            const { API } = this;
            let shouldReschedule = true;
            try {
                let result;
                if (isProtected) {
                    const filename = this.downloadsMap.get(itemId).name;
                    await this.retryOpenProtectedFileRequest(itemId, filename);
                } else {
                    const status = this.downloadsMap.get(itemId).status;
                    if (status === 'ERROR' || status === 'PROCESSING') {
                        shouldReschedule = false;
                    } else {
                        result = await processStatusCheck(API, itemId);
                        shouldReschedule = this.processStatusCheckResult(itemId, result);

                    }
                }
            } catch (error) {
                shouldReschedule = this.processStatusCheckError(itemId, error, failOn500Status, isProtected);
            }
            if (shouldReschedule && !isProtected) {
                this.tryScheduleStatusCheck(itemId, processStatusCheck, failOn500Status);
            }
        }
    }

    private async retryOpenProtectedFileRequest(fileId, filename): Promise<DownloadActionResult> {
        const { API } = this.authSettingsStore;
        const result = await openFile({
            API,
            accessType: OpenOptions.download,
            fileId,
            isProtected: true,
        });
        this.uploadProtectedFileResult(fileId, filename, (result as DownloadActionResult));
        return result as DownloadActionResult;
    }

    uploadProtectedFileResult = (fileId: string, filename: string, result: DownloadActionResult): void => {
        if (this.downloadsMap.has(fileId)) {
            this.setBatchDownload(fileId, {
                id: fileId,
                name: filename,
                status: 'DONE',
            });
        }
        this.processSuccessfulResult(fileId, result);
        this.API.cancelRequest(fileId);
        clearTimeout(this.processManagementMap.get(fileId));
    };

    MFARequiredStatusUpdate = (fileId: string, filename: string): void => {
        this.setBatchDownload(fileId, {
            id: fileId,
            name: filename,
            status: 'PROCESSING',
        });
    };

    startSingleProtectedDownloadStatusCheck = (fileId: string, filename: string): void => {
        this.setBatchDownload(fileId, {
            id: fileId,
            name: filename,
            status: 'PENDING',
        });
        this.tryUpdateStatus(fileId, getFileDownloadStatus, true, true);
    };

    startSingleDownloadStatusCheck = (fileId: string, filename: string): void => {
        this.setBatchDownload(fileId, {
            id: fileId,
            name: filename,
            status: 'PENDING',
        });
        this.tryUpdateStatus(fileId, getFileDownloadStatus, true);
    };

    startBatchDownloadStatusCheck = (batchId: string, name: string): void => {
        this.setBatchDownload(batchId, {
            id: batchId,
            name,
            status: 'PENDING',
        });
        this.tryUpdateStatus(batchId, getBatchDownloadStatus, true);
    };

    updateBatchDownloadStatusWithError = (fileId: string, filename: string): void => {
        this.setBatchDownload(fileId, {
            id: fileId,
            name: filename,
            status: 'ERROR',
        });
    };

    private partialUpdateBatchDownload(batchId: string, dataPartial: Partial<AsyncDownloadItem>): void {
        const batchDownload = this.downloadsMap.get(batchId);
        if (batchDownload) {
            this.setBatchDownload(batchId, { ...batchDownload, ...dataPartial });
        }
    }

    private watchOnDownloads(): void {
        this.downloadsMapReaction = reaction<boolean>(
            () => !!this.downloadsMap.size,
            (value) => this.setIsProgressWidgetOpen(value),
        );
    }
}

export default BatchDownloadStore;
