import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UserManager } from 'oidc-client';

import appConfig, { EnvConfig } from '../config/env';
import {
    AuthHeader,
    AxiosAdapter,
    RequestConfig,
    RequestMethod,
} from './interfaces';

const REQUESTS_WITH_PAYLOAD: RequestMethod[] = ['post', 'put', 'patch'];
const REQUESTS_WITHOUT_PAYLOAD: RequestMethod[] = ['delete', 'get'];

const getApiUrl = (config: EnvConfig): string => (config.API_PROXY ? config.API_PROXY.API_URL : config.API_URL);

class AxiosAmplifyWrapper implements AxiosAdapter {
    /*
    * Decorator for axios, which provides API like AWS Amplify. Required for authorize /access API requests
    */
    private readonly userManager: UserManager;

    private readonly httpInstance: AxiosInstance;

    constructor(userManager: UserManager) {
        this.userManager = userManager;
        this.httpInstance = axios.create({
            baseURL: getApiUrl(appConfig),
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    private async tryGetAuthHeaders(): Promise<Partial<AuthHeader>> {
        try {
            const user = await this.userManager.getUser();
            return {
                Authorization: `Bearer ${user.access_token}`,
                SpecterxUserId: user.profile.sub,
            };
        } catch {
            return {};
        }
    }

    private async tryGetHeaders(params: RequestConfig): Promise<Record<string, string>> {
        const authHeaders: Partial<AuthHeader> = await this.tryGetAuthHeaders();
        const customHeaders = params.headers || {};
        return { ...authHeaders, ...customHeaders };
    }

    private async getRequestParams(init: RequestConfig): Promise<RequestConfig> {
        const headers = await this.tryGetHeaders(init);
        return { ...init, headers };
    }

    private async sendRequest(
        endpoint: string,
        init: RequestConfig,
        method: RequestMethod,
    ): Promise<any> {
        const { headers, body, ...other } = await this.getRequestParams(init);
        const options = { headers, ...other };
        let callback;
        if (REQUESTS_WITH_PAYLOAD.includes(method)) {
            callback = async () => this.httpInstance[method](endpoint, body, options);
        } else if (REQUESTS_WITHOUT_PAYLOAD.includes(method)) {
            callback = async () => this.httpInstance[method](endpoint, options);
        }
        const axiosResponse: AxiosResponse = await callback();
        return init.response ? axiosResponse : axiosResponse.data;
    }

    async get(apiName: string, endpoint: string, init: RequestConfig): Promise<any> {
        return this.sendRequest(endpoint, init, 'get');
    }

    async del(apiName: string, endpoint: string, init: RequestConfig): Promise<any> {
        return this.sendRequest(endpoint, init, 'delete');
    }

    async patch(apiName: string, endpoint: string, init: RequestConfig): Promise<any> {
        return this.sendRequest(endpoint, init, 'patch');
    }

    async put(apiName: string, endpoint: string, init: RequestConfig): Promise<any> {
        return this.sendRequest(endpoint, init, 'put');
    }

    async post(apiName: string, endpoint: string, init: RequestConfig): Promise<any> {
        return this.sendRequest(endpoint, init, 'post');
    }
}

export default AxiosAmplifyWrapper;
