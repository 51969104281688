import React, {
    FC,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { observer } from 'mobx-react';

import Modal, {
    DynamicStyles,
    InitModalStyle,
} from '../Common/Modal';
import {
    CombinedSettingsModal,
    DeleteModal,
    OpenFileModal,
    RevokeModal,
    ShareModal,
} from './Modals';
import Spinner from '../Common/Spin';
import InfiniteScrollTable from './InfiniteScrollTable';
import FileInfoDrawer from './InfiniteScrollTable/FileInfoDrawer';
import { ChildTypes } from './InfiniteScrollTable/constants';
import { useStores } from '../hooks';
import type { SecuredFile } from '@/types/types';
import { AppRoutes } from '@/config/appRoutes';
import type { FileActionType } from './FileAction/menuItems';
import { OpenOptions } from '@/config/openOptions';
import { getLinkToViewer } from '../utils';
import styles from './FilesTable.module.scss';
import './antOverride.scss';
import MFAModal from '@/components/Common/MFAModal';
import { MFA_ATTEMPTS } from '@/consts';

const nameSpace = 'filesTable';

type ModalType = Exclude<FileActionType, OpenOptions.viewer | OpenOptions.webdav | 'audits'>;

const MODAL_DYNAMIC_STYLES: DynamicStyles = {
    width: true,
    wrapClassName: true,
};

const INIT_STYLE: InitModalStyle = {
    initWidth: 520,
};

const FilesTable: FC = observer(() => {
    const [fileSelected, setFileSelected] = useState<SecuredFile>(null);
    const [modalType, setModalType] = useState<ModalType>(null);

    const navigate = useNavigate();

    const { filesListStore, filesAccessStore, batchDownloadStore } = useStores();
    const { isMyFiles, selectedItem } = filesListStore;
    const {
        setProtectedFileInfo,
        isMFAMounted,
        setIsMFAVisible,
        setIsMFAMounted,
    } = filesAccessStore;
    const { startSingleDownloadStatusCheck } = batchDownloadStore;

    const hasAccessInfoDrawer = !!((isMyFiles || filesListStore.currentFolder?.menu?.edit_shares) && selectedItem);

    const findFile = (fileId: string): SecuredFile => {
        if (filesListStore?.currentFolder?.file_id === fileId) {
            return filesListStore?.currentFolder;
        }
        return filesListStore.filesToDisplay.find(({ fid }) => fid === fileId);
    };

    useEffect(() => {
        if (filesListStore.currentFolder?.is_workspace
            && (
                isMyFiles || filesListStore.currentFolder?.menu?.edit_shares
            )) {
            filesListStore.setChosenTableFileId(filesListStore.currentFolder?.file_id);
            setFileSelected(filesListStore.currentFolder);
        }
    }, [filesListStore.currentFolder?.file_id]);

    const closeMFA = useCallback((): void => {
        setIsMFAVisible(false);
        setIsMFAMounted(false);
    }, []);

    const handleFileAction = useCallback((action: FileActionType, fileId: string): void => {
        const file = findFile(fileId);
        if (!(file?.fid)) {
            message.error(`${nameSpace}.messages.couldNotFind`);
        } else {
            switch (action) {
            case OpenOptions.viewer:
            case OpenOptions.webdav:
                window.open(getLinkToViewer(fileId));
                break;
            case OpenOptions.download:
                if (!file.is_folder) {
                    startSingleDownloadStatusCheck(file.file_id, file.filename);
                    setProtectedFileInfo({
                        fileId: file.file_id,
                        filename: file.filename,
                        accessType: action,
                    });
                } else {
                    filesAccessStore.tryStartBatchDownload([file.file_id]);
                }
                break;
            case 'audits':
                navigate(`${AppRoutes.auditLogs}?file_id=${fileId}`);
                break;
            default:
                setFileSelected(file);
                setModalType(action);
                break;
            }
        }
    }, []);

    const resetModalDefault = useCallback((): void => {
        setModalType(null);
        setFileSelected(null);
    }, []);

    const getModalChildren = (): JSX.Element => {
        switch (modalType) {
        case OpenOptions.office365:
        case OpenOptions.googledrive:
            return (
                <OpenFileModal
                    closeModal={resetModalDefault}
                    fileSelected={fileSelected}
                    app={modalType}
                />
            );
        case 'delete':
        case 'delete_file':
            return (
                <DeleteModal
                    fileSelected={fileSelected}
                    closeModal={resetModalDefault}
                />
            );
        case 'revoke':
            return (
                <RevokeModal
                    fileSelected={fileSelected}
                    closeModal={resetModalDefault}
                />
            );
        case 'set_policy':
        case 'set_file_policy':
            return (
                <CombinedSettingsModal
                    closeModal={resetModalDefault}
                    file={fileSelected}
                    updateFilesList={filesListStore.updateFile}
                />
            );
        case 'manage_access':
            return (
                <CombinedSettingsModal
                    closeModal={resetModalDefault}
                    file={fileSelected}
                    updateFilesList={filesListStore.updateFile}
                    startMenuItem={'shareSettings' as ChildTypes}
                />
            );
        case 'share':
            return (
                <ShareModal
                    closeModal={resetModalDefault}
                    fileSelected={fileSelected}
                />
            );
        default:
            return null;
        }
    };

    return (
        <>
            <div className={styles['files-wrapper']}>
                <Spinner
                    fullHeight
                    screenCentered
                    spinning={filesListStore.isLoading}
                    wrapperClassName="spin-wrapper"
                >
                    <div className={styles['files-table-card']}>
                        <InfiniteScrollTable
                            handleFileAction={handleFileAction}
                            hasAccessInfoDrawer={hasAccessInfoDrawer}
                        />
                        { hasAccessInfoDrawer && (
                            <FileInfoDrawer handleFileAction={handleFileAction} />
                        )}
                    </div>
                </Spinner>
            </div>
            {!!modalType && (
                <Modal
                    isOpen={!!modalType}
                    closeModal={resetModalDefault}
                    dynamicStyles={MODAL_DYNAMIC_STYLES}
                    initStyle={INIT_STYLE}
                >
                    {getModalChildren()}
                </Modal>
            ) }
            {isMFAMounted && <MFAModal />}
        </>
    );
});

export default FilesTable;
