import React, { FC, useCallback } from 'react';

import { message, Modal } from 'antd';

import { t } from 'i18next';
import { MFA_ATTEMPTS } from '@/consts';
import AttemptsRemainWarning from '../../OpenViewer/AttemptsRemainWarning';
import MfaComp from '../../AppAuthenticator/MfaComp';
import { OpenOptions } from '@/config/openOptions.ts';
import { batchActionsNamespace } from '@/components/MyFiles/MyFilesHeader/ExtraContent/nameSpace.ts';
import { useStores } from '@/components/hooks';
import i18n from '@/content';

const MESSAGE_DURATION_SECONDS = 8;

const MFAModal: FC = () => {
    const { filesAccessStore, batchDownloadStore, filesListStore } = useStores();
    const {
        setIsMFAVisible,
        setIsMFAMounted,
        batchDownloadAttemptsCounter,
        isMFAVisible,
        tryFetchAccessLink,
        tryStartBatchDownload,
    } = filesAccessStore;
    const {
        setMFARequiredFilesList,
        downloadsList,
    } = batchDownloadStore;
    const {
        filesToDisplay,
    } = filesListStore;

    const closeMFA = useCallback((autoClosing: boolean = false): void => {
        if (!autoClosing) {
            const { MFARequiredFilesList, updateBatchDownloadStatusWithError } = batchDownloadStore;
            const filesData = filesToDisplay.filter((item) => MFARequiredFilesList.includes(item.file_id));
            filesData.forEach((item) => {
                updateBatchDownloadStatusWithError(item.file_id, item.filename);
            });
        }
        setMFARequiredFilesList([]);
        setIsMFAVisible(false);
        setIsMFAMounted(false);
    }, []);

    const onClickBatchLoading = async (mfaCode?: string): Promise<void> => {
        const { MFARequiredFilesList } = batchDownloadStore;
        const filesData = filesToDisplay.filter((item) => MFARequiredFilesList.includes(item.file_id));
        const anyFolders = filesData.some((item) => item.is_folder);
        let isSucceed = false;
        if (!MFARequiredFilesList.length) {
            message.info(t(`${batchActionsNamespace}.noFiles`), MESSAGE_DURATION_SECONDS);
        } else if (MFARequiredFilesList.length !== 1 || anyFolders) {
            isSucceed = await tryStartBatchDownload(MFARequiredFilesList, mfaCode);
        } else {
            const { name } = downloadsList.find((item) => item.id === MFARequiredFilesList[0]);
            isSucceed = await tryFetchAccessLink({
                fileId: MFARequiredFilesList[0],
                filename: name,
                accessType: OpenOptions.download,
                code: mfaCode,
            });
        }
        if (isSucceed) {
            closeMFA(true);
        } else {
            message.error(t('recipientPage.errors.notAuthorisedCode'), MESSAGE_DURATION_SECONDS);
        }
    };

    const onMfaClick = useCallback(async (mfaCode: string): Promise<void> => {
        setIsMFAVisible(false);
        if (mfaCode) {
            await onClickBatchLoading(mfaCode);
        }
    }, []);

    return (
        <Modal
            open={isMFAVisible}
            centered
            footer={null}
            onCancel={() => closeMFA(false)}
            maskClosable={false}
        >
            <>
                {batchDownloadAttemptsCounter < MFA_ATTEMPTS && (
                    <AttemptsRemainWarning attemptsCounter={batchDownloadAttemptsCounter} />
                )}
                <MfaComp onMfaClick={onMfaClick} />
            </>
        </Modal>
    );
};

export default MFAModal;
